import React from "react";
import './Home.css';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

function Home() {

  const {t} = useTranslation()

  return (
    <>
      <Helmet>
        <title>
          Polaris Synergies - {t('Welcome')}
        </title>
        <meta name="description" content={t('Polaris Synergies offers services to industrial companies in terms of strategic development and operational performance.')}/>
      </Helmet>
      <div className="home">
        <h1 className="display-6 mb-5 text-center translated-title">{t('Our expertise to boost the development and the profitability of your company')}</h1>
        <div className="discover-container">
          <button type="button" className="btn btn-dark btn-lg discover" onClick={()=>window.location.href="/about-us"}>{t('Discover us')}</button>
        </div>
      </div>
    </>
  );
}

export default Home;