import React from "react";
import './NotFound.css';
import { Helmet } from 'react-helmet';
import {useTranslation} from 'react-i18next';

function NotFound() {
    const {t} = useTranslation()

    return (
        <> 
            <Helmet>
                <title>
                    Polaris Synergies - {t('Not Found')}
                </title>
            </Helmet>
            <div className="not-found">
                <h1 className="display-1">
                    {t('Error 404')}
                </h1>
                <h2 className="lead fs-3">
                    {t('You seem lost...')}
                </h2>
                <button type="button" className="btn btn-dark btn-lg back-to-home" onClick={()=>window.location.href="/home"}>{t('Take me back')}</button>
            </div>
        </>
    );
}

export default NotFound;