import React from "react";
import './Header.css';
import logo from '../assets/Logo_Polaris_Synergies.svg';
import etoile from '../assets/Etoile_Couleurs.svg';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

function Header() {

  const { t } = useTranslation()

  function changeLanguage(lang) {
    i18next.changeLanguage(lang)
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container-fluid">
        {/* <a className="navbar-brand" href="/home">Polaris Synergies</a> */}
        <a className="logo-link" href='/home'>
          <img className="logo" src={logo} alt="polaris synergies' logo" />
        </a>

        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">

            <li className="nav-item">
              {window.location.pathname === "/about-us"
                ? <a className="nav-link active" aria-current="page" href="/about-us">{t('About us')}</a>
                : <a className="nav-link" href="/about-us">{t('About us')}</a>
              }
            </li>

            <li className="nav-item">
              {window.location.pathname === "/services"
                ? <a className="nav-link active" aria-current="page" href="/services">{t('Our range of services')}</a>
                : <a className="nav-link" href="/services">{t('Our range of services')}</a>
              }
            </li>

            <li className="nav-item">
              {window.location.pathname === "/references"
                ? <a className="nav-link active" aria-current="page" href="/references">{t('Sectors of activity & references')}</a>
                : <a className="nav-link" href="/references">{t('Sectors of activity & references')}</a>
              }
            </li>

            <li className="nav-item">
              {window.location.pathname === "/contact"
                ? <a className="nav-link active" aria-current="page" href="contact">{t('Contact')}</a>
                : <a className="nav-link" href="contact">{t('Contact')}</a>
              }
            </li>

          </ul>
        </div>
        {document.documentElement.getAttribute('lang') === 'fr' ? <></> : <button type="button" className="changeLang" data-mdb-ripple-color="dark" onClick={() => changeLanguage('fr')} lang='fr'>Voir en Français</button>}
        {document.documentElement.getAttribute('lang') === 'en' ? <></> : <button type="button" className="changeLang" data-mdb-ripple-color="dark" onClick={() => changeLanguage('en')} lang='en'>See in English</button>}
        <a href='/home'>
          <img className="logo-small" src={etoile} alt="polaris synergies' logo" />
        </a>
      </div>
    </nav>
  );
}

export default Header;