import React from "react";
import './Footer.css';
import {useTranslation} from 'react-i18next';

function Footer() {
  const {t} = useTranslation()

  return (
    <div className="footer navbar-light bg-light">
      <div className="side-footer">

      </div>
      <footer className="py-1 my-0">
        <ul className="nav justify-content-center border-bottom pb-2 mb-0 footer-nav">
          <li className="nav-item"><a href="/about-us" className="nav-link px-2 text-muted">{t('About us')}</a></li>
          <li className="nav-item"><a href="services" className="nav-link px-2 text-muted">{t('Our range of services')}</a></li>
          <li className="nav-item"><a href="references" className="nav-link px-2 text-muted">{t('Sectors of activity & references')}</a></li>
          <li className="nav-item"><a href="contact" className="nav-link px-2 text-muted">{t('Contact')}</a></li>
        </ul>
        <small className="text-center text-muted mb-0">© {t('Polaris Synergies SARL company, 15 avenue Anatole France, 94 100 Saint-Maur-des-Fossés, France')}</small>
      </footer>
      <div className="side-footer">
        <a href="https://www.linkedin.com/company/polaris-synergies/" target="_blank" rel="noreferrer"><i className='fab fa-linkedin fa-lg' /></a>
      </div>
    </div>
  );
}

export default Footer;