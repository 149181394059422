import React from "react";
import './Services.css';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

function Services() {

    const {t} = useTranslation()
    
    return (
        <>
            <Helmet>
                <title>Polaris Synergies - {t('Our range of services')}</title>
                <meta name="description" content={t('Polaris Synergies’ main services: Mergers & Acquisitions from identification until post-merger integration, turn-arounds, project and contract management, release of bank guarantees, interim management, mentoring…')}/>
            </Helmet>
            <div className="services">
                <div className="services-title-container mb-2">
                    <h1 className="display-6">{t('Our range of services')}</h1>
                </div>

                <div className="first-part">
                    <h2 className="display-6">{t('Strategic Development')}</h2>
                    <ul className="list-group list-group-flush mb-5">
                        <li className="list-group-item service-1">
                            <p className="lead">{t('Services to acquirers for M&As: identification, preliminary analysis of targets, pre-evaluation, due diligences, negotiations, post-merger integration (PMI)')}</p>
                        </li>
                        <li className="list-group-item service-2">
                            <p className="lead">{t('Support to vendors for M&As: search for acquirers, company valuation based on future cash-flows, sales negotiations')}</p>
                        </li>
                        <li className="list-group-item service-3">
                            <p className="lead">{t('Turn-around and restructuring of companies')}</p>
                        </li>
                        <li className="list-group-item service-13">
                            <p className="lead">{t('Business Intelligence')}</p>
                        </li>
                        <li className="list-group-item service-4">
                            <p className="lead">{t('Management of technology transfer license agreements, protection of intellectual property')}</p>
                        </li>
                        <li className="list-group-item service-5">
                            <p className="lead">{t('Streamlining of legal entities, pre-liquidation of companies')}</p>
                        </li>
                    </ul>
                </div>

                <div className="second-part">
                    <h2 className="display-6">{t('Operational performance')}</h2>
                    <ul className="list-group list-group-flush mb-5">
                        <li className="list-group-item service-6">
                            <p className="lead">{t('Project management (from purchase order until last payments)')}</p>
                        </li>
                        <li className="list-group-item service-7">
                            <p className="lead">{t('Contract management: negotiation of variation orders, claims, litigation')}</p>
                        </li>
                        <li className="list-group-item service-7">
                            <p className="lead">{t('Expert witness services for power, energy, global engineering and equipment industries : technical, quantum and delay')}</p>
                        </li>
                        <li className="list-group-item service-8">
                            <p className="lead">{t('Release of international bank guarantees more specifically for difficult countries (Morocco, Tunisia, Algeria, Egypt…)')}</p>
                        </li>
                        <li className="list-group-item service-9">
                            <p className="lead">{t('Recovery of overdues, cash-flow optimization')}</p>
                        </li>
                        <li className="list-group-item service-10">
                            <p className="lead">{t('Reorganization of accounting to automatically generate integrated full costs in a group of companies with Intraco sales (™G.S.I.A. methodology)')}</p>
                        </li>
                        <li className="list-group-item service-11">
                            <p className="lead">{t('Interim management')}</p>
                        </li>
                        <li className="list-group-item service-12">
                            <p className="lead">{t('Mentoring of managers')}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Services;