import React, { Suspense } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import './i18n';
import i18next from 'i18next';

import Home from './components/Home.js';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import NotFound from './components/NotFound.js';
import About from './components/About.js';
import Contact from './components/Contact.js';
import References from './components/References.js';
import Services from './components/Services.js';

function setLang(lng) {
  // console.log(lng.substring(0,2))
  if ((lng.substring(0, 2)) === "fr" || ((lng.substring(0, 2)) === 'en'))
    document.documentElement.setAttribute('lang', lng)
}

function App() {
  i18next.on('languageChanged', (lng) => { setLang(lng); });

  return (
    <div className="App">
      <Suspense fallback={null}>
        <Header />

        <Router>
          <Routes>

            <Route path='/' element={<Navigate replace to='/home' />} />

            <Route exact path="/home" element={<Home />} />

            <Route exact path="/about-us" element={<About />} />

            <Route exact path="/services" element={<Services />} />

            <Route exact path="/references" element={<References />} />

            <Route exact path="/contact" element={<Contact />} />

            <Route exact path="/404" element={<NotFound />} />

            <Route path='*' element={<Navigate to='/404' />}></Route>

          </Routes>
        </Router>

        <Footer />
      </Suspense>
    </div>
  );
}

export default App;
