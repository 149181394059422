// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/boussole.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contact{\n    flex: auto;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: 100% auto;\n    background-repeat: no-repeat;\n}\n\n.contact-content{\n    animation: pop-up 0.5s;\n    display: flex;\n    flex-direction: column;\n    justify-content: top;\n    align-items: center;\n    color: white;\n}\n\n.email{\n    color: white;\n}\n\n.contact > contact-content > a {\n    color:white;\n}\n\n@keyframes pop-up{\n    from{\n        transform: scale(0);\n        opacity: 0.25;\n    }\n\n    to{\n        transform: scale(1);\n        opacity: 1;\n    }\n}", "",{"version":3,"sources":["webpack://./src/components/Contact.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,yDAAgD;IAChD,0BAA0B;IAC1B,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI;QACI,mBAAmB;QACnB,aAAa;IACjB;;IAEA;QACI,mBAAmB;QACnB,UAAU;IACd;AACJ","sourcesContent":[".contact{\n    flex: auto;\n    background-image: url('../assets/boussole.jpeg');\n    background-size: 100% auto;\n    background-repeat: no-repeat;\n}\n\n.contact-content{\n    animation: pop-up 0.5s;\n    display: flex;\n    flex-direction: column;\n    justify-content: top;\n    align-items: center;\n    color: white;\n}\n\n.email{\n    color: white;\n}\n\n.contact > contact-content > a {\n    color:white;\n}\n\n@keyframes pop-up{\n    from{\n        transform: scale(0);\n        opacity: 0.25;\n    }\n\n    to{\n        transform: scale(1);\n        opacity: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
