import React from "react";
import './Contact.css';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';

function Contact(){

    const {t} = useTranslation()
    
    return(
        <>
            <Helmet>
                <title>Polaris Synergies - {t('Contact')}</title>
                <meta name="description" content={t('Contact by e-mail: contact@polaris-synergies.com')}/>
            </Helmet>
            <div className="contact">
                <div className="contact-content">
                    <h1 className="display-6 mt-5 mb-5 text-center">{t('How to contact us ?')}</h1>
                    <p className="lead mb-0">{t('By e-mail')} :</p>
                    <a className="lead mb-5 email" href = "mailto:contact@polaris-synergies.com">contact@polaris-synergies.com</a>
                </div>
            </div>
        </>
    );
}

export default Contact;